import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import { useStaticQuery, graphql, Link } from 'gatsby'

import bg from '../images/eat_fit_abq_background.png'
import logo from '../images/eat_fit-logo.png'
import background from '../images/bg.jpg'
import facebook from '../images/facebook-icon.png'
import twitter from '../images/twitter-icon.png'

import './layout.scss'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <style jsx="true" global="true">
        {`
          body {
            margin: 0px;
            padding: 0px;
            background: url(${background});
            background-repeat: repeat-x;
            background-color: #3a3522;
          }
        `}
      </style>
      <Helmet title={data.site.siteMetadata?.title} />
      <div
        style={{
          position: 'relative',
          background: `url(${bg})`,
          backgroundRepeat: 'no-repeat',
          height: 840,
          width: 980,
          margin: 'auto',
          marginTop: 8,
        }}
      >
        <img
          style={{ position: 'absolute', left: 10, top: 3 }}
          src={logo}
          alt="logo"
        />
        <div
          className="sidebar-link"
          style={{
            position: 'absolute',
            top: 305,
            left: 166,
          }}
        >
          <Link className="sidebar-link" to="/">
            Home
          </Link>
        </div>
        <div
          className="sidebar-separator"
          style={{ position: 'absolute', top: 335, left: 21 }}
        />
        <div
          className="sidebar-link"
          style={{
            position: 'absolute',
            top: 339,
            left: 123,
          }}
        >
          <Link className="sidebar-link" to="/a-la-carte">
            A la Carte
          </Link>
        </div>
        <div
          className="sidebar-separator"
          style={{ position: 'absolute', top: 367, left: 21 }}
        />
        <div
          className="sidebar-link"
          style={{
            position: 'absolute',
            top: 372,
            left: 115,
          }}
        >
          <Link className="sidebar-link" to="/meals">
            Meal Plans
          </Link>
        </div>
        <div
          className="sidebar-separator"
          style={{ position: 'absolute', top: 400, left: 21 }}
        />
        <div
          className="sidebar-link"
          style={{
            position: 'absolute',
            top: 405,
            left: 65,
          }}
        >
          <Link className="sidebar-link" to="/next-weeks-menu">
            Next Weeks Menu
          </Link>
        </div>
        <div
          className="sidebar-separator"
          style={{ position: 'absolute', top: 433, left: 21 }}
        />
        <div
          className="sidebar-link"
          style={{
            position: 'absolute',
            top: 438,
            left: 111,
          }}
        >
          <Link className="sidebar-link" to="/testimonials">
            Testimonials
          </Link>
        </div>
        <div
          className="sidebar-separator"
          style={{ position: 'absolute', top: 466, left: 21 }}
        />
        <div
          className="sidebar-link"
          style={{
            position: 'absolute',
            top: 471,
            left: 135,
          }}
        >
          <Link className="sidebar-link" to="/about">
            About Us
          </Link>
        </div>
        <div
          className="sidebar-separator"
          style={{ position: 'absolute', top: 499, left: 21 }}
        />
        <div
          className="sidebar-link"
          style={{
            position: 'absolute',
            top: 504,
            left: 121,
          }}
        >
          <Link className="sidebar-link" to="/contact">
            Contact Us
          </Link>
        </div>
        <div style={{ position: 'absolute', top: 790, left: 235 }}>
          <a href="https://www.facebook.com/Eatfit-by-Nohime-462976420438133/">
            <img src={facebook} alt="facebook icon" />
          </a>
        </div>
        <div style={{ position: 'absolute', top: 790, left: 258 }}>
          <a href="https://twitter.com/EatFitAbq">
            <img src={twitter} alt="twitter icon" />
          </a>
        </div>
        <div
          style={{
            color: 'white',
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontSize: 16,
            position: 'absolute',
            top: 790,
            left: 510,
          }}
        >
          Delivery Included!*
        </div>
        <div
          style={{
            color: 'white',
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontSize: 16,
            position: 'absolute',
            top: 790,
            left: 765,
          }}
        >
          © 2021 Eat Fit by Nohime
        </div>
        <div
          style={{
            color: 'white',
            fontFamily: 'Arial, Helvetica, sans-serif',
            fontSize: 12,
            position: 'absolute',
            top: 818,
            left: 360,
          }}
        >
          *Delivery limited to Albuquerque, NM &amp; Rio Rancho, NM.
        </div>
        <div>
          <main>{children}</main>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
